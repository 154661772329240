.announcement-header {
    font-size: 14px !important;
    background-color: #a6bcdc !important;    
}
.announcement-header-margin {
    margin-left: 22px;  
    margin-right: 22px;
}
.announcement-heading {
 font-size: 14px;
 font-weight: bold;
}
.announcement-body {
font-size:12px !important;
padding-top:4px;
}