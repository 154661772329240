div.coming-soon-overlay {
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    float: left;
    top: 270px;
    width: 1010px;
    height: 773px;
    background: rgba(0,0,0,0.2);
    z-index: 999;
    position: absolute;
    text-align: center;
    vertical-align: middle;
    padding-top: 200px;
    font-size: 100px;
    font-family: fantasy;
    margin-left: -13px;
}